import React, { useEffect, useState } from "react";
import {
  Page,
  Layout,
  Frame,
  Button,
  FooterHelp,
  LegacyCard,
  LegacyStack,
} from "@shopify/polaris";
import {
  ClockMajor,
  StatusActiveMajor,
  CircleDisabledMajor,
} from "@shopify/polaris-icons";
import OnboardingComponent from "../Onboarding/OnboardingComponent";
import {
  buildAppEmbedDeepLink,
  buildAppBlockDeepLink
} from "../Utils/ExtensionDeepLinkBuilder";
import { GET_SALE_STATISTICS } from "../GraphQL/Queries/TaskStatus";
import { useQuery, useMutation } from "@apollo/client";
//import { GetPlanName } from "../Subscription/SubscriptionPlan";
import { GET_ONBOARDING_STATUS } from "../GraphQL/Queries/Settings";
import { SAVE_ONBOARDING_STATUS } from "../GraphQL/Mutations/Settings";
import "./DashboardPage.css";

export default function DashboardPage({
  plan,
  storeUrl = "",
  appEmbedUuid = "a65575de-72bd-46c3-a21f-5b009e85bc92",
  hasCreatedFirstDiscount = false,
  hasCustomizedDesign = false,
  hasDismissedOnboarding = false,
  hasInstalledAppEmbed = false,
  hasModifiedAppBlockPlacement = false,
  hasModifiedAppEmbedFeaturedCollections = false,
}) {
  const { data: onboardingStatus, refetch: refetchOnboarding } =
    useQuery(GET_ONBOARDING_STATUS(), { fetchPolicy: "cache-and-network" }) ||
    {};
  const { data: saleStatistics } =
    useQuery(GET_SALE_STATISTICS(), { fetchPolicy: "cache-and-network" }) || {};
  const [saveOnboarding] = useMutation(SAVE_ONBOARDING_STATUS());
  const [onboardingDismissed, setOnboardingDismissed] = useState(
    hasDismissedOnboarding,
  );

  const onFocus = () => {
    refetchOnboarding();
    window.removeEventListener("focus", onFocus);
  };

  const [steps, setSteps] = useState([
    {
      title: "Install countdown timer and announcement bar app embed",
      description: (
        <>
          <p>
            To use the Disco sale widgets, you must first enable the Disco App
            Embed in your store theme.
          </p>
          <p>
            Click the button below and press <b>Save</b> in the next window.
          </p>
          <div className="Polaris-Banner__PrimaryAction">
            <a
              className="Polaris-Button"
              href={buildAppEmbedDeepLink(storeUrl, appEmbedUuid, "app_embed")}
              onClick={() => window.addEventListener("focus", onFocus)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Install app embed
            </a>
          </div>
        </>
      ),
      mediaSrc:
        "https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/onboarding/home-guides-offer-discount-b1e850100e6b3b773f1abf54cf50c246a59eca429e7684e189385b6752fe07a8.svg",
      completed: hasInstalledAppEmbed,
      editable: false,
    },
    {
      title: "Create your first discount campaign",
      description: (
        <>
          <p>
            Add discount to your products and display promotional widgets such
            as timers, announcement bars, sale badges and sale pages.{" "}
            <a
              href="https://productessentials.helpscoutdocs.com/article/32-flash-sale-page"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </p>
          <Button url="/create">Create campaign</Button>
        </>
      ),
      mediaSrc:
        "https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/onboarding/home-guides-offer-discount-b1e850100e6b3b773f1abf54cf50c246a59eca429e7684e189385b6752fe07a8.svg",
      completed: hasCreatedFirstDiscount,
    },
    {
      title: "Customize countdown timer and announcement bar design",
      description: (
        <>
          <p>
            Modify the background color, text color, fonts and dimensions of the
            sale widgets.
          </p>
          <Button
            url="/design"
            onClick={() => {
              handleCompletionToggle(2);
            }}
          >
            Customize design
          </Button>
        </>
      ),
      mediaSrc:
        "https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/onboarding/detail-images/customize-theme-task-d2760705452ef967a48b5fc4a6dce1ea9fc2fe4566dea18bb0927a746abb349f.svg",
      completed: hasCustomizedDesign,
      editable: true,
    },
    {
      title: "Change placement of countdown timer and announcement bar",
      description: (
        <>
          <p>
            Place product page timer and announcement bar using Store Theme
            editor. Requires Online Store 2.0 theme.{" "}
            <a
              href="https://productessentials.helpscoutdocs.com/article/42-how-to-setup-custom-placement-for-countdown-timer-and-announcement-bar-in-non-online-store-2-0-compatible-shopify-themes"
              target="_blank"
              rel="noopener noreferrer"
            >
              Not Online Store 2.0 theme? Click here.
            </a>
          </p>
          <LegacyStack>
            <div className="Polaris-Banner__PrimaryAction">
              <a
                className="Polaris-Button"
                href={buildAppBlockDeepLink(
                  storeUrl,
                  appEmbedUuid,
                  "countdown_timer_block",
                )}
                onClick={() => {
                  handleCompletionToggle(3);
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Place countdown timer
              </a>
            </div>
            <div className="Polaris-Banner__PrimaryAction">
              <a
                className="Polaris-Button"
                href={buildAppBlockDeepLink(
                  storeUrl,
                  appEmbedUuid,
                  "announcement_bar_block",
                  "sectionGroup:header",
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                Place announcement bar
              </a>
            </div>
          </LegacyStack>
        </>
      ),
      mediaSrc:
        "https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/onboarding/home-guides-offer-discount-b1e850100e6b3b773f1abf54cf50c246a59eca429e7684e189385b6752fe07a8.svg",
      completed: hasModifiedAppBlockPlacement,
      editable: true,
    },
    {
      title: "Enable sale badges on home page collection(s)",
      description: (
        <>
          <p>
            To enable sale badges on featured collections, press the button
            below and click <b>Select collections</b> in left hand side to
            select which collections are featured on the home page.
          </p>
          <div className="Polaris-Banner__PrimaryAction">
            <a
              className="Polaris-Button"
              href={buildAppEmbedDeepLink(storeUrl, appEmbedUuid, "app_embed")}
              onClick={() => {
                handleCompletionToggle(4);
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Select featured collections
            </a>
          </div>
        </>
      ),
      mediaSrc:
        "https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/onboarding/home-guides-offer-discount-b1e850100e6b3b773f1abf54cf50c246a59eca429e7684e189385b6752fe07a8.svg",
      completed: hasModifiedAppEmbedFeaturedCollections,
      editable: true,
    },
  ]);

  useEffect(() => {
    if (onboardingStatus) {
      const updatedSteps = [...steps];
      updatedSteps[0].completed =
        onboardingStatus.onboardingStatus.HasInstalledAppEmbed;
      setSteps(updatedSteps);
    } // eslint-disable-next-line
  }, [onboardingStatus]);

  const activeSalesCount = saleStatistics
    ? saleStatistics.saleStatistics.ActiveCount
    : "-";
  const endedSaleCount = saleStatistics
    ? saleStatistics.saleStatistics.EndedCount
    : "-";
  const scheduledSaleCount = saleStatistics
    ? saleStatistics.saleStatistics.ScheduledCount
    : "-";

  const handleCompletionToggle = (index) => {
    const updatedSteps = [...steps];
    updatedSteps[index].completed = !updatedSteps[index].completed;
    setSteps(updatedSteps);
    doSaveOnboarding(updatedSteps);
  };

  const doSaveOnboarding = async (updatedSteps, dismiss = false) => {
    try {
      await saveOnboarding({
        variables: {
          input: {
            HasCreatedFirstDiscount: updatedSteps[1].completed,
            HasCustomizedDesign: updatedSteps[2].completed,
            HasDismissedOnboarding: dismiss,
            HasInstalledAppEmbed: updatedSteps[0].completed,
            HasModifiedAppBlockPlacement: updatedSteps[3].completed,
            HasModifiedAppEmbedFeaturedCollections: updatedSteps[4].completed,
          },
        },
      });
    } catch (e) {
      console.error("failed saving onboarding: " + e);
    }
  };

  const dismissOnboarding = () => {
    setOnboardingDismissed(true);
    doSaveOnboarding(steps, true);
  };

  const openSupportBeacon = () => {
    if (window.Beacon) {
      window.Beacon("open");
      window.Beacon("navigate", "/ask/message");
    }
  };

  return (
    <Frame>
      <Page
        title="Dashboard"
        forceRender
        primaryAction={{
          content: "Create sale",
          url: "/create",
        }}
      >
        <Layout>
          {/*<Layout.Section>
            <Card sectioned>
              <p>Your are on the <b>{GetPlanName(plan)}</b> plan.</p>
            </Card>
          </Layout.Section>*/}
          <Layout.Section>
            <LegacyCard sectioned title="Campaigns">
              <LegacyStack spacing="loose">
                <Button
                  url="/status"
                  fullWidth
                  textAlign="left"
                  icon={ClockMajor}
                  variant="plain"
                >
                  Scheduled: <b>{scheduledSaleCount}</b>
                </Button>
                <Button
                  url="/status"
                  fullWidth
                  textAlign="left"
                  icon={StatusActiveMajor}
                  variant="plain"
                >
                  Active: <b>{activeSalesCount}</b>
                </Button>
                <Button
                  url="/status"
                  fullWidth
                  textAlign="left"
                  icon={CircleDisabledMajor}
                  variant="plain"
                >
                  Ended: <b>{endedSaleCount}</b>
                </Button>
              </LegacyStack>
            </LegacyCard>
          </Layout.Section>
          {!onboardingDismissed && (
            <Layout.Section>
              <OnboardingComponent
                title="Setup guide"
                description="Use this guide to setup Disco in your store and create your first sale campaign."
                steps={steps}
                setStepCompletion={handleCompletionToggle}
                onDismiss={dismissOnboarding}
                onSupport={openSupportBeacon}
              />
            </Layout.Section>
          )}
          <Layout.Section>
            <FooterHelp>
              Need help? E-mail <a href="mailto:support@productessentials.app">support@productessentials.app</a> or <Button variant="plain" onClick={() => window.Beacon('open')}>contact us via in-app live chat</Button>. Expect live responses on weekdays between 9-17 (Central European Time, CET), otherwise we strive to reply within 24 hours.
            </FooterHelp>
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
  );
}
