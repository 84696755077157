import { gql } from '@apollo/client';

export function GET_TASK_STATUS() {
  // change to flyApi:      tasks @rest(type: "Tasks", path: "/tasks/status", method: "GET", endpoint: "discoFlyApi") {
  return gql`
    query GetTaskStatus {
      tasks @rest(type: "Tasks", path: "/tasks/status", method: "GET") {
        Id
        Title
        DoneCount
        SelectionCount
        Requested
        Status
        Filters
        ProductSelectionType
        ExcludeInactiveProducts
        SaleType
        ProductDiscountMapping
        Action
        EndDate
        StartDate
        LastUpdate
        ThumbnailSource
        EnableSaleTag
        SaleTagLayout
        EnableSalePage
        SalePageLayout
        EnableComingSoonPage
        ComingSoonPageLayout
        PageHandle
        PagePublished
      }
    }
  `;
}

export function GET_TASK() {
  return gql`
    query GetTaskStatus($saleId: String) {
      task(saleId:$saleId) @rest(type: "Tasks", path: "/tasks/{args.saleId}", method: "GET") {
        Id
        Title
        DoneCount
        SelectionCount
        Requested
        Status
        Filters
        ProductSelectionType
        ExcludeInactiveProducts
        SaleType
        ProductDiscountMapping
        Action
        EndDate
        StartDate
        LastUpdate
        ThumbnailSource
        Announcement
        EnableSaleTag
        SaleTagLayout
        EnableSalePage
        SalePageLayout
        EnableComingSoonPage
        ComingSoonPageLayout
        PageHandle
        PagePublished
      }
    }
  `;
}

export function GET_SALE_STATISTICS() {
  return gql`
    query GetSaleStatistics {
      saleStatistics @rest(type: "Tasks", path: "/tasks/statistics", method: "GET") {
        ScheduledCount
        ActiveCount
        EndedCount
      }
    }
  `;
}