export const SubscriptionPlan = Object.freeze({
    Inactive: 0,
    Starter: 1,
    Builder: 2,
    Grower: 3,
    Scaler: 4,
    Extreme: 100,
  });

export const GetPlanName = (plan) => {
    switch (plan) {
        case SubscriptionPlan.Builder:
            return "Builder";
        case SubscriptionPlan.Grower:
            return "Grower";
        case SubscriptionPlan.Scaler:
            return "Scaler";
        case SubscriptionPlan.Extreme:
            return "Extreme";
        case SubscriptionPlan.Starter:
        default:
            return "Starter";
    }
}