import React, { useEffect, useState } from "react";
import {
  LegacyCard,
  LegacyStack,
  Button,
  ProgressBar,
  ButtonGroup,
  Popover,
  ActionList,
  Icon,
  Tooltip,
  Text,
} from "@shopify/polaris";
import {
  HorizontalDotsMinor,
  ChevronDownMinor,
  ChevronUpMinor,
  CircleTickMajor,
} from "@shopify/polaris-icons";
import "./OnboardingComponent.css";

const OnboardingComponent = ({
  title = "",
  description = "",
  steps = [],
  setStepCompletion = (index) => {},
  setSteps = () => {},
  onDismiss = () => {},
  onSupport = () => {},
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isPopoverActive, setIsPopoverActive] = useState(false);
  const [expandedStep, setExpandedStep] = useState(null);
  const completedStepsCount = steps.filter((step) => step.completed).length;

  useEffect(() => {
    const firstIncompleteStep = steps.findIndex((step) => !step.completed);
    setExpandedStep(
      firstIncompleteStep !== -1 ? firstIncompleteStep : completedStepsCount,
    );
  }, [steps, completedStepsCount]);

  return (
    <LegacyCard>
      <LegacyCard.Header title={title}>
        <ButtonGroup>
          <Popover
            active={isPopoverActive}
            activator={
              <Button
                icon={HorizontalDotsMinor}
                onClick={() => {
                  setIsPopoverActive(!isPopoverActive);
                }}
                variant="plain"
              ></Button>
            }
            onClose={() => {
              setIsPopoverActive(false);
            }}
          >
            <ActionList
              items={[
                { content: "Dismiss", onAction: onDismiss },
                { content: "Get support", onAction: onSupport },
              ]}
            />
          </Popover>
          <Button
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? ChevronDownMinor : ChevronUpMinor}
            variant="plain"
          ></Button>
        </ButtonGroup>
      </LegacyCard.Header>
      <LegacyCard.Section>
        <LegacyStack vertical>
          <p>{description}</p>
          <LegacyStack>
            <LegacyStack.Item>
              <Text variant="bodyMd" as="span" tone="subdued">
                {completedStepsCount} of {steps.length} completed
              </Text>
            </LegacyStack.Item>
            <LegacyStack.Item fill>
              <ProgressBar
                progress={(completedStepsCount / steps.length) * 100}
              />
            </LegacyStack.Item>
          </LegacyStack>
        </LegacyStack>
      </LegacyCard.Section>
      {!isCollapsed && (
        <LegacyCard.Section>
          <LegacyStack vertical spacing="baseTight">
            {steps.map((step, index) => (
              <div key={index}>
                <div
                  className={
                    expandedStep === index
                      ? "onboarding-step expanded"
                      : "onboarding-step"
                  }
                >
                  {step.editable && (
                    <div
                      className="icon"
                      onClick={() => setStepCompletion(index)}
                    >
                      <Tooltip
                        content={`Mark as ${step.completed ? "not done" : "done"}`}
                        dismissOnMouseOut
                        preferredPosition="above"
                      >
                        {step.completed && <Icon source={CircleTickMajor} />}
                        {!step.completed && (
                          <span className="circle-empty"></span>
                        )}
                      </Tooltip>
                    </div>
                  )}
                  {!step.editable && (
                    <div className="icon">
                      {step.completed && <Icon source={CircleTickMajor} />}
                      {!step.completed && (
                        <span className="circle-empty"></span>
                      )}
                    </div>
                  )}
                  <div
                    className="content-and-media"
                    onClick={() => setExpandedStep(index)}
                  >
                    <div className="content-box">
                      <div className="heading">
                        {expandedStep !== index && <>{step.title}</>}
                        {expandedStep === index && <b>{step.title}</b>}
                      </div>
                      {expandedStep === index && (
                        <div className="content">
                          <p>{step.description}</p>
                        </div>
                      )}
                    </div>
                    {expandedStep === index && step.mediaSrc && (
                      <div className="media">
                        <img src={step.mediaSrc} alt={step.title} />
                        <div className="fade"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </LegacyStack>
        </LegacyCard.Section>
      )}
    </LegacyCard>
  );
};

export default OnboardingComponent;
