import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Config from "./Utils/Config";
import { camelCaseKeys } from "./Utils/StringUtils";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SubscriptionPlan } from "./Subscription/SubscriptionPlan";
import axios from "axios";
import * as serviceWorker from "./serviceWorker";

/*import { onCLS, onFID, onLCP } from 'web-vitals';
onCLS(console.log, {reportAllChanges: true});
onFID(console.log, {reportAllChanges: true});
onLCP((lcpData) => {
  if (lcpData && lcpData.entries && lcpData.entries.length && lcpData.entries.length > 0) {
    if (lcpData.entries[0].element) {
      console.log("LCP " + lcpData.entries[0].element.id + " elm:");
      console.log(lcpData.entries[0].element);
      console.log(lcpData);
    }
  }
}, {reportAllChanges: true});*/

const authQueryString = window.location.search.split("?")[1];
const installUrl = `${Config.flyApi.URL}/install/${Config.appName}?${authQueryString}`;

Sentry.init({
  dsn: Config.sentryDsn,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const authenticate = async () => {
  const installStatus = await axios.get(installUrl);

  if (installStatus.status === 200) {
    const onboardingStatus = camelCaseKeys(installStatus.data.OnboardingStatus);
    
    if (installStatus.data.Plan >= SubscriptionPlan.Starter) {
      ReactDOM.render(<App plan={installStatus.data.Plan} freeDiscountsLeft={installStatus.data.FreeDiscountsLeft} requestVatNumber={installStatus.data.NeedsVatNumber} missingScopes={installStatus.data.MissingScopes} onboardingStatus={onboardingStatus} />, document.getElementById("root"));
    } else {
      // SubscriptionPlan.Inactive is used as intermediate subscription plan when upgrading from SubscriptionPlan.Starter.
      // If the current plan is SubscriptionPlan.Inactive, it means we are upgrading and need to redirect to the upgrade URL, as done below.
      if (window.top === window.self) {
        window.location.assign(installStatus.data.RedirectUrl);
      } else {
        ReactDOM.render(<App plan={installStatus.data.Plan} freeDiscountsLeft={installStatus.data.FreeDiscountsLeft} requestVatNumber={installStatus.data.NeedsVatNumber} missingScopes={installStatus.data.MissingScopes} redirectUrl={installStatus.data.RedirectUrl} onboardingStatus={onboardingStatus} />, document.getElementById("root"));
      }
    }
  }
};

if (authQueryString === undefined) {
  document.getElementById("root").innerText = "Cannot authenticate, please reload app";
}
else {
  authenticate();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
